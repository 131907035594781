// App-generic
html {
	height: 100vh;
}

p.highlight {
	color: $spgreen;
}

.gradient {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: -moz-linear-gradient(top,  rgba(32,85,39,0) 0%, rgba(32,85,39,0.7) 32%, rgba(32,85,39,1) 53%, rgba(32,85,39,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(32,85,39,0) 0%,rgba(32,85,39,0.7) 32%,rgba(32,85,39,1) 53%,rgba(32,85,39,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(32,85,39,0) 0%,rgba(32,85,39,0.7) 32%,rgba(32,85,39,1) 53%,rgba(32,85,39,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00205527', endColorstr='#205527',GradientType=0 ); /* IE6-9 */
}

.bg-green {
	background: $spgreen;
}

.white-box {
	border-radius: 11px;
	background-color: #ffffff;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: inherit;
	padding-left: inherit;

	.row {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		p {
			margin: 0;
		}

		.white-box-title {
			font-size: 16px;
			font-weight: bold;
		}

		.white-box-content {
			font-size: 16px;
			color: #000000;
		}
	}
}

body {
	margin: 0;
	padding: 0;
	color: #222;
	font-size: 16px;
	min-height: 100%;
	background: $spbggreen;
	color: white;

	* {
		&:active, &:focus {
			box-shadow: none !important;
			outline: none !important;
		}
	}

	a {
		cursor: pointer;
	}

	img {
		max-width: 100%;
	}

	hr {
		border-color: white;
		opacity: 0.3;
		margin: 1em 2em 2em;
	}

	.text-black {
		color: $spblack;
	}

	.content-background-wrapper {
		
		&.center {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.banner {
		margin-top: 92px;
		width: 100%;
		margin-bottom: 20px;
		@include aspect-ratio(16, 3);
		.ratio-container {
			background-image: url('../../assets/backgrounds/pub-background-wide-bright.jpg');
           	@include background-size(cover);
           	display: flex;

           	&:before {
           		content: "";
			    @include background-overlay;
           	}

       		#page-title {
       			margin: auto auto 30px auto;
				width: 1140px;
				position: relative;
				z-index: 9;

				@include mobile {
					padding-left: 25px;
				}
       		}
        }
	}

	.page-button-container {
		margin: 20px auto;
		width: 50%;
	}

	#search-container {
		position: relative;
		margin-bottom: 16px;

		#search-icon {
			position: absolute;
			top: 0;
			left: 20px;
			height: 20px;
			width: 20px;
			bottom: 0;
			margin: auto 0;
		}

		#search {
			border-radius: 40px;
			border: 2px solid $spwhite;
			padding: 10px 0;
			text-indent: 50px;
			height: auto;
			margin-bottom: 0;

			&:before {

			}

			&::placeholder {
				color: gray;
			}
		}
	}

	.table-header {
		height: 45px;
		padding: 0 20px;
		line-height: 25px;
		margin: 10px 0;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #666;
		border-radius: 0;

		&#sort-bar {
			position: fixed;
			bottom: 80px;
			left: 0;
			right: 0;
			padding: 0;

			.container {
				padding: 0;
				> div {
					padding: 10px 0;

					&.col-md-1 {
						text-align: center;
					}
				}
			}
		}

		&.order-header {
			line-height: 21px;
			margin-bottom: 10px;
		}

		&.blank {
			background-color: transparent;
		}

		p {
			margin: 0;
		}

		>div {
			display: flex;
			align-items: center;

			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				padding-right: 0;
			}
		}

		margin-bottom: 20px;
	}

	.text-center, .text-right, .text-left {
		display: block;
		width: 100%;
	}

	#page-title, .page-title {
		margin: 25px 0;
		.page-sub-title {
			display: block;
			color: white;
			font-size: 16px;
		}
	}

	.row {
		margin: 0;
	}

	.clickable {
		cursor: pointer;
	}

	.container {
		width: 100%;
		max-width: 1200px;
		padding: 0;
		min-width: 300px;
		margin: 0 auto;
		position: relative;
	}

	a {
		color: $spgreen;
		text-decoration: none;

		&:hover {
			text-decoration: none;
			color: lighten($spgreen, 10%);
		}

		&:active, &:focus {
			outline: none !important;
		}
	}

	@import '_inputs';

	.jumbotron {
		background-color: $spwhite;
		border-color: $spwhite;
		border-width: 1px;
		border-style: solid;
		padding: 10px 15px;
		color: $spblack;
		border-radius: 10px;

		* {
			color: $spblack;
		}

		&.light {
			background-color: $spgray;
			border-color: $spwhite;

			input {
				border-color: $spblack;
			}
		}

		&.dark {
			background-color: transparent;
			border-color: transparent;

			* {
				color: $spwhite;
			}
		}

		&.blue {
			background-color: $spgreen;
			border-color: $spgreen;
		}

		&.blue.dark {
			background-color: transparent;
			border-color: $spgreen;
		}

		.jumbotron-title, .jumbotron-icon, .jumbotron-content, .jumbotron-content * {
			padding: 0;
		}

		.jumbotron-title {
			margin-bottom: 10px;
		}
	}
}