#feedback {
	.container {
		#stars-container {
			margin-bottom: 25px;
			text-align: center;

			img {
				height: 35px;
				margin-right: 20px;
			}
		}
	}
}