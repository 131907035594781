#stock-count {
	.container {

		>.row >.container {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

#stock-count-update {
	.container {
		max-width: 1200px;

		.table-header {
			>div {
				&:first-of-type {
					padding-left: 0;
				}

				&:last-of-type {
					padding-right: 0;
				}
			}
		}

		#product-list {

			>.row>div {
				padding: 0;

				&:last-of-type {
					padding-left: 10px;
				}

				&:first-of-type {
					padding-right: 10px;
				}
			}
		}
	}
}