#favourite-order {

	.btn.has-icon {
		margin: 25px 0 0;

		&.medium {
			margin-top: 0;
		}
	}

	#product-list-table {
		#product-list {
			.product-list-item {
				padding: 6px 10px;

				.editable-content {
					display: flex;
					align-items: center;

					.content {
						margin-left: 12px;

						a {
							background: white;
							color: black;
							width: 30px;
							display: block;
							text-align: center;
							height: 30px;
							border-radius: 50%;
							line-height: 30px;
							border: 1px solid #1fa6a6;
							font-size: 12px;
						}
					}
				}

				.product-list-item-value {
					.editable {
						margin-top: 0;
						// margin-left: 12px;
						background: white;
						color: black;
						width: 30px;
						display: block;
						text-align: center;
						height: 30px;
						border-radius: 50%;
						line-height: 30px;
						border: 1px solid #1fa6a6;
						font-size: 12px;
					}
				}
			}
		}
	}

	#category-list-table {
		margin-top: 50px;

		#category-list {
			padding: 0;

			h5 {
				margin-bottom: 15px;
			}

			.btn-primary {
				.row {
					border-bottom: 1px solid #666;
					padding-bottom: 15px;
				}
			}
		}
	}

	&.product-category {
		#product-list-table {
			#product-list {
				.product-list-item {
					.product-list-item-name {
						line-height: 30px;
					}
				}
			}
		}
	}
}