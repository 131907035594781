#footer {
	width: 100%;
	background-color: $spblack;
	overflow-y: hidden;
	margin-top: 100px;

	.container {
		padding: 0;

		.row {
			.col-lg {
				display: flex;
				align-items: center;
				justify-content: center;
				min-height: 100px;

				p, a {
					font-size: 12px;
					margin: 0;
					color: white;
				}

				a.first {
					margin-right: auto;
				}

				.logo {
					width: 139px;
					height: 32px;
					background-image: url('../../../assets/starlink/footer-logo.png');
					background-repeat: no-repeat;
				}
			}
		}
	}
}