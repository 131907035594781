#home {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include mobile {
		min-height: calc(100vh - 70px);
	}

	.hero {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		img {
			width: 100%;
		} 
	}

	.container.justify {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: calc(100vh - 292px);

		.editable-content {
			text-align: center;
			margin: 240px 0 10px;

			h1 {
				font-size: 2rem;
			}
		}
	}


	#logo {
		max-width: 240px;
		display: block;
		margin: 20px auto 40px;

		@include mobile {
			display: none;
		}
	}

	.header {
		margin-bottom: 20px;
	}

	.home-nav {
		.btn.small {
			.row {
				justify-content: center;

				.icon-wrapper {
					padding-left: 0;

					.icon {
						height: 24px;
					}
				}

				.icon-text {
					flex: 0 auto;
				}
			}
		}
	}

	#dates {
		margin-top: 15px;
		margin-bottom: 35px;
	}
}