#product-list-table {
	#page-title {
		margin: -84px 0 0 0;
		padding: 25px 0;
		height: 84px;
		background: black;
	}

	.col-md-1 {
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}

	.col-md-2 {
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-md-5 {
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}

	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}


	&.order-list {
		.product-list-item {

			.product-list-item-name {
		
			}

			.product-list-item-history {
				background-color: rgba(31, 166, 166, 0.4);
				margin: 7px 0;
				padding: 10px 0 !important;

				.row {
					>div {
						margin: 0 !important;
						line-height: 23px;
					}
				}

				.history-item-arrow {
					height: 25px;
				}

				.history-item-calendar {
					height: 20px;
					margin-right: 7px;
				}
			}
		}
	}

	.table-header {

		.col-md-3 {
			.text-right {
				white-space: nowrap;
			}
		}
	}
	

	#product-list {
		.product-list-item {
			display: block;
			&:nth-child(2n) {
			    background: #062121;
			}

			&.active {
				background-color: $spgreen;
			}

			.row {
				img {
					height: 1.6em;
				}

				.col-md-12 {
					padding: 0;
				}

				.col-md-1 {
					padding-right: 20px;
					display: flex;
					justify-content: end;
					align-items: center;
					a {
						img.pin, img.arrow, img.remove {
							height: 15px;

						}

						img.arrow-down {
							width: 15px;
						}
					}
				}

				.product-list-item-value {
					a {
						img.pin, img.arrow, img.remove {
							height: 25px;
						}

						img.arrow-down {
							width: 25px;
						}
					}
				}

				.product-list-item-left {
					padding-left: 18px;
					text-align: left;

					.product-list-item-image {
						float: left;
						height: 50px;
						width: 50px;
						margin: 5px 30px 5px 0;
						border-radius: 7px;
						background-color: $spwhite;
						text-align: center;
						overflow: hidden;

						img {
							height: 50px;
						}
					}

					.product-list-item-name {
						display: flex;
						align-items: center;
						height: 100%;

						&.sortable {
							line-height: 30px;
						}

						p {
							margin: 0;
						}
					}
				}

				.product-list-item-value {
					&.sortable {
						.editable {
							margin-top: 0 !important;
						}
					}

					.editable {
						background-color: $spwhite;
						color: $spblack;
						border-radius: 100px;
						height: 32px;
						width: 32px;
						display: block;
						border: $spsmborder solid $spgreen;
						text-align: center;
						line-height: 27px;
						margin: 0 auto;
					}

					.uneditable {
						background-color: transparent;
						color: $spwhite;
						border-radius: 100px;
						height: 32px;
						width: 32px;
						display: block;
						border: $spborder solid $spgreen;
						text-align: center;
						line-height: 27px;
						margin: 0 auto;
					}
				}

				.product-list-item-right {
					padding-right: 0;
					text-align: right;
					display: flex;
					justify-content: flex-end;
					align-items: center;

					.editable-content {
						display: flex;
						align-items: center;

						& > div {
							margin-right: 0.8em;
							&:last-child {
								margin-right: 0;
							}
						}
					}

					.product-list-item-value {
						.editable {
							margin-right: 20px;
						}

						a img.star-icon {
							height: 30px;
							width: auto;
						}
					}
				}
			}
		}
	}
}

#product-list-buttons {
	margin-top: 50px;
}