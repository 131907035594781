.dropdown {
    > button {
        background-image: url('../../../assets/icons/SmartPub-arrow-right.svg');
        background-size: 0.5em;
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 1.5em;
        margin-bottom: 0;
        text-align: right;
    }

    &.show > button {
        background-image: url('../../../assets/icons/SmartPub-arrow-down.svg');
        background-size: 0.8em;
    }

    .dropdown-menu {
        padding: 0;
        overflow: hidden;

        .dropdown-item {
            &.active {
                background: $spgreen;
            }

            &:not(.active):hover {
                background: $spgray;
            }
        }
    }
}