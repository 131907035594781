$spgreen: #205527;
$spbggreen: #638868;
$splightgreen: #13670b;
$spwhite: #ffffff;
$spblack: #000000;
$spgray: #f0f0f0;
$spgradientgreen: #0b3b3b;
$spred: #D80000;
$spsmborder: 1.5px;
$spborder: 2px;

$mobile-width: 1000px;
$desktop-width: 1024px;


//Mixins

@mixin gradient($dir: to top, $from: $spgradientgreen, $to: $spblack 55%) {
	background: linear-gradient($dir, $from, $to) no-repeat 100% 100%;
}

@mixin background-size($size) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: $size;
    -ms-behavior: url(/wp-default/wp-content/themes/default/vendor/backgroundsize.min.htc);
}

@mixin background-overlay($opacity: 0.2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, $opacity);
}


@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .ratio-container {
        position: absolute;
        top: 0; 
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$mobile-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}


//Generic

::-webkit-input-placeholder {
	color: #ffffff;
}

:-ms-input-placeholder {
	color: #ffffff;
}

::placeholder {
	color: #ffffff;
}