#history {
	.container {

		.col-md-12 {
			padding: 0;

			.btn.has-icon {
				margin: 0;
			}

			.history {
				.col-md-2 {
					flex: 0 0 16.666667%;
					max-width: 16.666667%;
				}

				.col-md-9 {
					flex: 0 0 75%;
					max-width: 75%;
				}
			}
		}

		.table-header {
			margin-bottom: 10px;
		}
	}
}

#history-order {
	.container {

		.table-header {
			&:not(.blank){
				margin-top: 15px;
			}

			&.blank {
				padding: 0;

				.col-md-2:last-of-type {
					padding-right: 15px;
				}
			}
		}

		#product-list-table {
			#product-list {
				margin-top: 15px;

				.product-list-item {
					.product-list-item-image {
						margin-left: 20px;
					}
				}

				.product-list-item-value {
					display: flex;
					align-items: center;
				}
			}
		}
	}
}