#root .btn {
	width: 100%;
	background-color: $splightgreen;
	border-width: $spsmborder;
	border-style: solid;
	border-color: $splightgreen;
	text-align: center;
	border-radius: 40px;
	font-size: 1.1rem;
	margin-bottom: 16px;
	color: $spwhite;

	&.btn-disabled {
		opacity: 0.5 !important;
		cursor: default !important;
	}

	&.btn-small {
		width: auto;
		font-size: 0.8rem;
		margin-bottom: 0;
		height: auto;
	}

	&.text-light {
		color: $spwhite !important;
	}

	&.text-dark {
		color: $spblack !important;
	}

	&.text-green {
		color: $spgreen !important;
	}

	&.has-icon {
		height: auto;
		text-align: left;
		border-radius: 11px !important;
		padding-top: 17px;
		padding-bottom: 17px;

		&.small {
			&.blank {
				padding-top: 10px;
				padding-bottom: 10px;
				margin: 0;
			}
		}

		&.medium {
			text-align: center;

			&.blank {
				padding-top: 0.375rem;
				padding-bottom: 0.375rem;
			}

			.icon-text {
				margin-top: 7px;
			}
		}

		.col-md-4 {
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}

		.col-md-8 {
			flex: 0 0 66.666667%;
			max-width: 66.666667%;
		}

		.icon-wrapper{
			

			&.highlighted, &.blank {
				border-radius: 10px;
				width: 60px;
				max-width: 60px;
				text-align: center;
				padding: 0;

				img {
					height: 37px;
					width: auto;
				}
			}

			&.highlighted {
				height: 60px;
				border: 1px solid $spgreen;
				background-color: $spgreen;
			}

			&.blank {
				border: 0;
				height: 0;
			}
		}

		.icon-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 1em 0 0.5em;
		}

		.icon-text {
			flex: 1;
			text-align: center;

			font-size: 18px;
			line-height: 40px;
			text-align: left;

			&.blank {
				height: 0;
				line-height: 0 !important;
				margin-top: 12px;
			}
		}

		.icon-arrow {
			text-align: right;
			padding: 0;

			&.blank {
				img {
					margin-top: 0;
				}
			}

			&.open {
				img {
					height: 8.4px;
					width: 15px;
				}
			}

			img {
				height: 15px;
				width: 8.4px;
				margin-top: 22.5px;
			}
		}

		&.big {
			.icon-wrapper {
				padding: 0;
			}

			.icon-text {
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 22px;
				line-height: 25px;
			}
		}
	}

	&.green {
		background-color: $spgreen;
		border-color: $spgreen;
		color: $spwhite;

		&:focus, &:hover, &:active {
			background-color: $spgreen !important;
			border-color: $spgreen !important;

			&.text-light {
				color: $spwhite !important;
			}

			&.text-dark {
				color: $spblack !important;
			}

			&.text-green {
				color: $spgreen !important;
			}
		}
	}

	&.primary,
	&.light {
		color: $spgreen;
		background-color: $spwhite;
		border-color: $spwhite;

		&:focus, &:hover, &:active {
			background-color: $spwhite !important;
			border-color: $spwhite !important;
			color: $spgreen !important;

			&.text-light {
				color: $spwhite !important;
			}

			&.text-dark {
				color: $spblack !important;
			}

			&.text-green {
				color: $spgreen !important;
			}
		}
	}

	&.secondary,
	&.dark {
		background-color: $spblack;
		color: $spwhite;
		border-color: $spgreen;

		&:focus, &:hover, &:active {
			background-color: $spblack !important;
			border-color: $spgreen !important;

			&.text-light {
				color: $spwhite !important;
			}

			&.text-dark {
				color: $spblack !important;
			}

			&.text-green {
				color: $spgreen !important;
			}
		}
	}

	&.blank {
		background-color: transparent;
		border-color: transparent;

		&.border-white {
			border-color: $spwhite;
		}

		&:focus, &:hover, &:active {
			background-color: transparent !important;
			border-color: transparent !important;

			&.border-white {
				border-color: $spwhite !important;
			}
		}
	}

// Starting again
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 10px;

	&.btn--icon {	
		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			top: 0;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	&.btn--primary {
		font-size: 22px;
		height: 78px;
		border-radius: 8px;
		&.btn--icon {	
			padding: 10px 10px 10px 76px;
		
			&::before {
				width: 50px;
				height: 50px;
				margin: 14px;
			}
		}
	}

	&.btn--secondary {
		font-size: 20px;
		height: 50px;
		border-radius: 30px;
		width: auto;

		&.btn--icon {	
			padding: 8px 18px 8px 60px;
		
			&::before {
				width: 30px;
				margin: 0 14px 0 18px;
			}
		}
	}

	&.btn--order::before {
		background-image: url('../../../assets/icons/SmartPub-nav-order-icon.svg');
	}

	&.btn--history::before {
		background-image: url('../../../assets/icons/SmartPub-history-icon.svg');
	}

	&.btn--cart::before {
		background-image: url('../../../assets/starlink/icons/checkout.svg');
	}
}