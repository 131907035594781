.login-forgot {
	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p {
			margin-bottom: 40px;
		}

		#logo {
			max-width: 200px;
			display: block;
			margin: 0px auto 70px;
		}

		.form-group {
			width: 80%;
			margin-bottom: 20px;
		}
	}
}