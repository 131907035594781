#root .block {
	background-color: white;
	color: black;
	display: flex;
	flex-direction: column;
	text-align: center;
	border-radius: 10px;
	margin-bottom: 30px;

	.last {
		display: flex;
		margin-top: auto;
	}

	.header {
		display: flex;
		padding-top: 20px;
		h4 {
			display: flex;
			align-items: center;
			font-size: 14px;
			border: 1px solid $splightgreen;
			border-radius: 20px;
			padding-right: 20px;

			&::before {
				content: '';
				display: block;
				width: 40px;
				height: 40px;
				margin: 0 4px;
				background-size: 20px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		&.header-big {
			display: flex;
			align-items: center;
			width: 100%;
			padding-bottom: 8px;
			border-bottom: 1px solid #fff;
			margin-bottom: 20px;

			.icon {
				margin-left: auto;
			}
		}
	}

	.header-circle {
		color: $spgreen;
		border: 1px solid $spgreen;
		padding: 7px 24px 7px 12px;
		border-radius: 40px;
		text-align: center;
		display: flex;
		max-width: 200px;
		margin-bottom: 14px;
		align-items: center;
		&::before {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			margin-right: 10px;
		}

		&.header-circle--ship {
			&::before {
				background-image: url('../../../assets/starlink/icons/location-icon.svg');
			}
		}
	}

	.spacing {
		padding: 20px;
	}

	.content {
		width: 100%;
		text-align: left;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;

		h5 {
			color: $spgreen;
			margin-bottom: 10px;
		}

		.note {
			background: #f1f1f2;
			padding: 8px;
			border-radius: 4px;
			font-size: 12px;
			margin: 20px 0;
			display: flex;
			justify-content: center;
			align-items: center;

			&::before {
				background-image: url('../../../assets/starlink/icons/alert-icon.svg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				content: '';
				min-width: 30px;
				height: 30px;
				display: block;
				margin-right: 14px;
			}
		}

		.bold-link {
			font-weight: bold;
			margin-right: auto;
		}
	}

	.footer {
		padding-top: 16px;
		display: flex;
		justify-content: center;
	}

	&.block--home {
		height: calc(100% - 30px);
	}

	&.block--history {
		.history-list {

			a {
				display: flex;
				align-items: center;
				padding: 14px 0;

				&::after {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					margin-right: 10px;
					background-image: url('../../../assets/starlink/icons/green-arrow-icon.svg');
					background-repeat: no-repeat;
					background-position: center;
				}

				.date {
					text-align: center;
					color: black;
					font-size: 12px;
					&::before {
						content: '';
						display: block;
						width: 40px;
						height: 40px;
						background-image: url('../../../assets/starlink/icons/calendar-range-icon.svg');
						background-repeat: no-repeat;
						background-position: center;
						margin: 0 auto 4px;
					}
				}

				.order-details {
					.order-ref {
						font-weight: bold;
					}

					.order-total {
						font-weight: bold;
						color: black;
					}
				}

				.order-progress {
					border: 1px solid $spgreen;
					padding: 7px 24px;
					border-radius: 40px;
					text-align: center;
					display: inline-block;
				}

				&:nth-of-type(2n) {
					background-color: rgba(0, 0, 0, 0.05);
				}
			}
		}
	}


	&.block--history-order {
		table {
			margin-bottom: 20px;
			tbody {
				border-top: 1px solid $spgreen;
				border-bottom: 1px solid $spgreen;
			}
		}
	}

	&.block--contact {
		text-align: left;
		color: #666;

		textarea {
			min-height: 215px;
		}

		h1 {
			font-size: 1.3em;
			color: $spgreen;
			font-weight: bold;
			margin-bottom: 20px;
		}

		div > div {
			border-top: 1px solid $spgreen;
			padding-top: 20px;
		}

		strong {
			font-size: 110%;
			color: black;
		}
	}

	&.block--summary {
		button.edit {
			text-align: left;
			justify-content: left !important;
			font-weight: bold;

			&::before {
				content: '';
				width: 20px;
				height: 14px;
				background-image: url('../../../assets/starlink/icons/green-arrow-icon.svg');
				background-repeat: no-repeat;
				background-size: contain;
				transform: rotate(180deg);
				margin-right: 10px;
			}
		}

		button.btn--order {
			background-color: $spgreen !important;
			&::before {
				width: 30px;
				margin: 0 14px 0 18px;
			}
		}
	}


	.shipping {
		h4 {
			&::before {
				background-image: url('../../../assets/starlink/icons/location-icon.svg');
			}
		}
	}

	.delivery {

		h4 {
			&::before {
				background-image: url('../../../assets/starlink/icons/green-truck.svg');
			}
		}
	}

	.history {
		h4 {
			&::before {
				background-image: url('../../../assets/starlink/icons/calendar-green.svg');
			}
		}
	}

	&.block--cleaning-info {
		h4 {
			&::before {
				background-image: url('../../../assets/icons/SmartPub-product-pos.svg');
			}
		}
	}

	&.block--financial-info {
		h4 {
			&::before {
				background-image: url('../../../assets/icons/SmartPub-product-pos.svg');
			}
		}

	}
}