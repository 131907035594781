#terms {

	#terms-content {
		background-color: $spwhite;
		padding: 15px 20px;
		margin: 35px 0 20px;
		
		* {
			color: $spblack;
		}
	}
}