#pub-info {
    table {
        th {
            width: 50%;
        }

        tr:first-child {
            th, td {
                border-top: 0;
            }
        }
    }
}