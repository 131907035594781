#init {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .loader,
    .updates {
        flex: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .loader {
        h4 {
            margin: 1em 0 0 0;
        }
    }

    .updates {
        flex: 1;
    }
}