#login {
	height: 100vh;
	display: flex;
	position: relative;
	background: white;

	> div {
		width: 100%;
	}

	.login-left {
		display: flex;
		flex: 1;
		justify-content: center;
		align-self: center;
		padding: 1em;

		.form {
			min-width: 350px;

			form {
				input {
					height: 54px;
					margin-bottom: 26px;
				}

				button {
					height: 54px;
				}


				.form-group {
					input {
						border: 1px solid $spgreen;
						text-align: center;
						border-radius: 40px;
					}
				}
			}
			#forgot {
				margin-top: 16px;
			}
		}
		#logo {
			max-width: 200px;
			display: block;
			margin: 0px auto 120px;
		}

		@include mobile {
			max-width: 100%;

			.form {
				min-width: 300px;
			}
		}
	}
	.login-right {
		display: flex;
		justify-content: center;	
		flex: 2;
		background-image: url('../../../assets/backgrounds/login-bg.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		overflow-y: auto;

		@include mobile {
			display: none;
		}

		.content-background-wrapper {
			width: 100%;
		}

		.contents {
			text-align: center;
			width: 100%;
			height: 100vh;
			display: flex;
			flex-direction: column;

			.welcome {
				width: 80%;
				max-width: 1200px;
				margin: 20px auto 40px;

				.icons {
					display: flex;
					justify-content: center;
					align-items: center;

					.icon {
						flex: 1;

						img {
							max-width: 80px;
						}
					}
				} 
			}

			.welcome-content {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				width: 80%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 80px;
				color: #fff;

				.rollup {
					display: inline-block;
					padding: 40px;
					flex: 0 50%;

					.icon-container {
						display: inline-block;
						height: 100px;
						text-align: center;
						
						img {
							height: 75px;
						}
					}

					.description {
						font-size: 14px;

						h1 {
							font-size: 1.75rem;
							font-weight: normal;
						}

						img {
							max-width: 120px;
							height: auto;
						}

						a {
							color: white;
						}
					}
				}
			}

			.welcome-footer {
				position: relative;
				min-height: 80px;
				background: $spgreen;
				display: flex;
				width: 100%;
				justify-content: center;
				align-items: center;
				margin-top: auto;

				a {
					color: white;
				}

				img {
					height: 40px;
				}

				* {
					display: inline-block;
					margin-left: 3em;

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}
}