input, textarea, select {
    padding: 7px 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid $spgreen;
    color: $spgreen;
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    height: 40px;
    font-size: 16px;

    &::placeholder {
        color: $spgreen;
    }
}

input[type=date],
select {
    border: 1px solid $spgreen;
    border-radius: 40px;
    padding: 5px 15px;

    option {
        color: black;
    }
}

input[type=date]::-webkit-calendar-picker-indicator {
    background: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    position: absolute;
    height: auto;
}

textarea {
    min-height: 100px;
    border: 1px solid $spgreen;
    border-radius: 20px;
    padding: 15px;
}

.calendar-container {
    position: relative;

    label {
        position: absolute;
        top: 1px;
        left: 1em;
        right: 1em;
        background-color: #000000;
        z-index: 2;
        pointer-events: none;
        bottom: 9px;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 1.3em;
        line-height: 38px;

        &.calendar-label--hidden {
            background: transparent !important;
            color: transparent;
        }
    }
}

#root .number-input {
    display: flex;
    button.btn {
        background: #808080;
        width: 30px;
        height: 30px;
        border: 0;
        margin: 0 7px;
    }

    input {
        border: 2px solid $spgreen;
        border-radius: 20px;
        min-height: 30px;
        min-width: 4em;
    }
}