#page-wrapper.page--order,
#page-wrapper.page--orderreview,
#page-wrapper.page--history,
#page-wrapper.page--historyorder {

	.block {
		h1 {
			font-size: 1.3em;
			color: $spgreen;
			font-weight: bold;
		}

		.icon {
			&::before {
				content: '';
				display: block;
				width: 24px;
				height: 24px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}

			&.address {
				position: relative;
				padding-left: 30px;
				
				&::before {
					position: absolute;
					top: 2px;
					left: -4px;
					background-image: url('../../../assets/starlink/icons/location-icon.svg');
				}
			}


			&.reference::before,
			&.notes::before {
				float: left;
				margin-right: 10px;
				background-image: url('../../../assets/starlink/icons/edit-button.svg');
			}
		}

		.content {
			h5 {
				color: $spgreen;
				margin-bottom: 4px;
			}

			textarea {
				border: 0;
				border-bottom: 1px solid $spgreen;
				border-radius: 0;
			}

			p.min-height {
				color: $spgreen;
				min-height: 80px;
			}
		}
	}

	table {
		input {
			width: 3em;
			height: 1.5em;
			margin: 0;
			text-align: center;
		}

		.icon {
			width: 1em;
		}
	}



	.btn-group {
		display: flex;
		flex-direction: column;

		.btn {
			position: relative;
			background: white;
			color: $spgreen;
			text-align: left;
			justify-content: left;
			opacity: 0.6;
			border: 0;
			font-weight: bold;

			padding: 1.2em;
			padding-left: 4em;

			&::before {
				content: '';
				display: block;
				width: 30px;
				height: 30px;
				border: 1px solid $spgreen;
				border-radius: 50%;
				position: absolute;
				left: 20px;
				top: 50%;
				margin-top: -15px;
			}
			&::after {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background: $spgreen;
				border-radius: 50%;
				position: absolute;
				left: 25px;
				top: 50%;
				margin-top: -10px;
				display: none;
			}

			&.active {
				opacity: 1;

				&::after {
					display: block;
				}
			}
		}
	}

	.notice {
		background: black;
		padding: 20px 20px 20px 80px;
		border-radius: 40px;
		position: relative;
		margin:  20px;

		&::before {
			content: '';
			display: block;
			width: 80px;
			height: 30px;
			background-image: url('../../../assets/starlink/icons/alert-white.svg');
			position: absolute;
			left: 0;
			background-position: center;
			background-repeat: no-repeat;
			top: 0;
			bottom: 0;
			height: 100%;
			background-size: 30px;
		}

		&.notice--delivery-cta {
			background: #FFCA00;
			color: #333;
			margin-left: 0;
			margin-right: 0;
			padding-left: 70px;

			&::before {
				background-image: url('../../../assets/starlink/icons/checkout-black.svg');
			}

			.btn {
				background: none;
				border: none;
				display: inline-block;
				width: auto;
				padding: 0;
				font-size: 1em;
				margin: 0;
				text-decoration: underline;
				float: right;
				color: #333;

				&:hover {
					background: none !important;
				}
			}
		}
	}

	.sticky {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;

		padding: 1em;
	}
}