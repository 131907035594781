table.table {
	margin-bottom: 0;
	thead {
		tr {
			th {
				text-align: left;
				font-weight: normal;
				text-transform: uppercase;
			}
		}
	}

	tbody {
		tr {
			td {
				text-align: left;
				p {
					margin-bottom: 0;
				}

				img {
					max-width: 50px;
				}
			}
		}
	}
}

#root .table-footer {
	padding: 20px 20px 10px;
	background: #808080;
	color: white;

	.row {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;

		.col-lg {
			margin-bottom: 14px;
		}
	}

	.textblock {
		background: black;
		display: inline-block;
		padding: 10px 20px;
		border-radius: 40px;
	}

	button.btn {
		margin-bottom: 0;
		border: 0;
		background-color: $splightgreen;
	}
}