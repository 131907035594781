.list-container {
	ul.list {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			&:nth-child(even) {
				background: #062121;
			}

			.list-contents {
				display: flex;
				padding: 14px 20px;

				.list-label {
					p {
						margin: 0;
					}
				}

				.list-edit {
					margin-left: auto;
					display: flex;
					justify-content: center;
					align-items: center;

					a {
						display: block;
						padding: 0 10px;

						img {
							width: 32px;
						}
					}
				}
			}

			ul.list--break {
				background: black;
				border-bottom: 1px solid white;
				padding: 10px 20px;
				margin: 50px 0 0;

				li {
					h3 {
						font-size: 20px;
					}
				}
			}
		}
	}
}